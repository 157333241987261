import React from 'react';

import { defineMessages, useIntl } from 'react-intl-next';

import Heading from '@atlaskit/heading';
import { Stack, Text } from '@atlaskit/primitives/compiled';

export const i18n = defineMessages({
	aiActionsSectionText: {
		id: 'ai-smart-button.ai-actions.actions.section.text',
		defaultMessage: 'Actions',
		description: 'The section text for the AI Actions in a Menu Group',
	},
	aiStudioSectionText: {
		id: 'ai-smart-button.ai-actions.rovo-agents.section.text',
		defaultMessage: 'Studio',
		description:
			'The section text for the AI Studio section (containing rovo agents) in a Menu Group',
	},
	noResultsHeading: {
		id: 'ai-smart-button.ai-actions.no-results-heading',
		defaultMessage: 'Ask Rovo',
		description: 'Heading when there are no actions for what the user has queried.',
	},
	noResultsText: {
		id: 'ai-smart-button.ai-actions.no-results-text',
		defaultMessage: 'Send your prompt to Rovo to start a chat.',
		description:
			'Description when there are no actions for what the user has queried. The description should prompt the user to send a chat query to Rovo.',
	},
});

export function NoResults() {
	const intl = useIntl();

	return (
		<Stack alignInline="center" grow="fill">
			<Stack space="space.200" alignInline="center">
				<Heading size="medium">{intl.formatMessage(i18n.noResultsHeading)}</Heading>
				<Text size="medium" color="color.text.subtle" align="center">
					{intl.formatMessage(i18n.noResultsText)}
				</Text>
			</Stack>
		</Stack>
	);
}

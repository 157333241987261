import React, { useContext } from 'react';

import { defineMessages, type MessageDescriptor, useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import ArrowLeftIcon from '@atlaskit/icon/core/arrow-left';
import { RovoIcon } from '@atlaskit/logo';
import { Inline, Text } from '@atlaskit/primitives/compiled';
import { DynamicSurfaceView, useAISmartButtonState } from '@atlassian/ai-smart-button-api';

// eslint-disable-next-line @atlassian/tangerine/import/no-parent-imports
import { AIDynamicSurface } from '../ai-dynamic-surface';
// eslint-disable-next-line @atlassian/tangerine/import/no-parent-imports
import type { ObjectSidebar } from '../common/types/object-sidebar-types';

import { AIDynamicSurfaceContext } from './AIDynamicSurfaceContext';

export const i18n = defineMessages({
	rovoPanelTitle: {
		id: 'ai-floating-context-menu.ai-dynamic-surface.rovo-panel-title',
		defaultMessage: 'Rovo',
		description: 'The header label of AI dynamic surface panel',
	},
	referencingPanelTitle: {
		id: 'ai-floating-context-menu.ai-dynamic-surface.referencing-panel-title',
		defaultMessage: 'Referencing',
		description: 'The header label of AI dynamic surface panel when context has reference',
	},
	backButton: {
		id: 'ai-floating-context-menu.ai-dynamic-surface.back',
		defaultMessage: 'back',
		description: 'Back button label in dynamic surface panel',
	},
});

const RovoLogo = () => {
	const intl = useIntl();

	return (
		<Inline alignBlock="center" space="space.050">
			<RovoIcon size="small" testId="dy-rovo-icon" />
			<Text size="medium" weight="bold" color="color.text.subtle">
				{intl.formatMessage(i18n.rovoPanelTitle)}
			</Text>
		</Inline>
	);
};

export const getDynamicSurfacePanelHeaderElements = ({
	BackButton,
	panelTitle,
}: {
	BackButton?: () => React.ReactNode;
	panelTitle?: MessageDescriptor;
}) => {
	const HeaderIcon = () => {
		if (BackButton) {
			return <BackButton />;
		}
		if (!panelTitle) {
			return <RovoLogo />;
		}
	};

	return {
		...((BackButton || !panelTitle) && {
			HeaderIcon,
		}),
		headerLabel: panelTitle,
		headerStyles: {
			setGrayBackground: !!panelTitle,
		},
	};
};

export const DYNAMIC_SURFACE_PANEL_ID = 'ai-dynamic-surface';

/**
 * This hook take care of updating object sidebar panel's
 * 	title and background color based on the context.
 * Use this hook only in the AIDynamicSurface component.
 */
export function useUpdateDynamicSurfacePanelHeaderComponents(objectSidebar: ObjectSidebar) {
	const intl = useIntl();
	const { activeAction, setActiveAction } = useContext(AIDynamicSurfaceContext);
	const { context, currentView } = useAISmartButtonState();
	const panelTitle = context?.reference ? i18n.referencingPanelTitle : undefined;
	const { changeHeaderElements } = objectSidebar.actions;
	/**
	 * Don't move creating BackButton outside this hook.
	 * Because it needs to use setActiveAction and can't use AIDynamicSurfaceContext there,
	 * 	it will rendered outside of AIDynamicSurface render tree.
	 */
	const onBackButtonClick = React.useCallback(() => {
		setActiveAction(undefined);
	}, [setActiveAction]);
	const BackButton = React.useCallback(() => {
		return (
			<IconButton
				appearance="subtle"
				icon={ArrowLeftIcon}
				label={intl.formatMessage(i18n.backButton)}
				onClick={onBackButtonClick}
				testId="dy-back-button"
			/>
		);
	}, [intl, onBackButtonClick]);
	/**
	 * Here it is assumed dynamic surface panel is already open.
	 */
	React.useEffect(() => {
		if (currentView === DynamicSurfaceView.Default) {
			changeHeaderElements(
				DYNAMIC_SURFACE_PANEL_ID,
				getDynamicSurfacePanelHeaderElements({
					panelTitle,
					/**
					 * Show back button when activeAction has children.
					 * AIActions component shows children actions only.
					 */
					BackButton: activeAction && activeAction.children ? BackButton : undefined,
				}),
			);
		}
	}, [activeAction, BackButton, objectSidebar, panelTitle, currentView, changeHeaderElements]);
}

export function useDynamicSurfacePanel(objectSidebar: ObjectSidebar) {
	const { context } = useAISmartButtonState();
	const panelTitle = context?.reference ? i18n.referencingPanelTitle : undefined;

	const dynamicSurfacePanel = React.useMemo(
		() => ({
			id: DYNAMIC_SURFACE_PANEL_ID,
			headerComponentElements: getDynamicSurfacePanelHeaderElements({ panelTitle }),
			BodyComponent: () => <AIDynamicSurface objectSidebar={objectSidebar} />,
		}),
		[objectSidebar, panelTitle],
	);

	return dynamicSurfacePanel;
}
